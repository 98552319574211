import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Anchor, InputAndButtonGrid, OrderWrapper } from '@components/ringil3/styles.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { findValidationMessage, findValidationMessageRaw } from '@utils/validation.tsx';
import { InputTextarea } from 'primereact/inputtextarea';
import { RowWithGap } from '@components/styles.tsx';
import DeleteButton from '@components/ringil3/Buttons/DeleteButton.tsx';
import InputSKU from '@components/ringil3/Input/InputSKU/InputSKU.tsx';
import InputTextWithSuffix from '@components/ringil3/Input/InputTextWithSuffix/InputTextWithSuffix.tsx';
import { parseNumberOrUndefined } from '@utils/utils.tsx';
import InputStackable from '@components/ringil3/Input/InputStackable/InputStackable.tsx';
import InputNoteWithLink from '@components/ringil3/Input/InputNoteWithLink/InputNoteWithLink.tsx';
import AddOrderItemButton from '@components/ringil3/Features/Expedition/AddOrderItemButton/AddOrderItem.tsx';
import AddOrderButton from '@components/ringil3/Features/Expedition/AddOrderButton/AddOrderButton.tsx';
import { emptyOrder, Order } from '@components/framework/orders/common.tsx';
import { Form } from '@hooks/useForm/useForm.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { fullRequiredConfig, OrdersConfig } from '@components/framework/orders/validation.ts';
import VisibitityComponent from '@components/framework/orders/VisibitiyComponent.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import InputPriceWithCurrency from '@components/framework/input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import { CargoTemplateWithoutIdDTO } from '@api/logsteo-api.v2.tsx';
import SvgLoadDown from '@icons/LoadDown.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  form: Form<{ orders: Order[] }>;
  config?: OrdersConfig;
  canBeEmpty?: boolean;
  generateOrderId?: boolean;
  onOrderAdded?: (orderId: string) => void;
  onDeleteOrder?: (orderId: string) => void;
  allUnloadingLocations?: { id: string; name: string }[];
}

const OrdersComponent: React.FC<ComponentProps> = ({
  form,
  config = fullRequiredConfig,
  canBeEmpty = false,
  generateOrderId = false,
  onOrderAdded = (a: string) => {},
  onDeleteOrder = (a: string) => {},
  allUnloadingLocations,
}) => {
  const { tr } = useTranslationLgs();

  const addOrder = () => {
    const order = emptyOrder(config.cargo.required, generateOrderId);

    form.setFieldValue(form.names.orders, [...form.values.orders, order], () => {
      onOrderAdded(order.id);
    });
  };

  const deleteOrder = (orderIndex: number) => {
    const order = form.values.orders.find((_, i) => i === orderIndex);
    form.setFieldValue(
      form.names.orders,
      form.values.orders.filter((t: any, i: number) => i !== orderIndex),
      () => {
        onDeleteOrder(order.id);
      },
    );
    //onChange(orders.filter((_, i) => i !== orderIndex));
  };

  const addOrderItem = (orderIndex: number) => {
    form.setFieldValue(`orders[${orderIndex}].items`, [
      ...form.values.orders[orderIndex].items,
      {
        itemNote: null,
        amount: null,
        cargoTemplateCode: null,
        stackable: false,
      },
    ]);
  };

  const deleteOrderItem = (orderIndex: number, itemIndex: number) => {
    form.setFieldValue(
      `orders[${orderIndex}].items`,
      form.values.orders[orderIndex].items.filter((item: any, i: number) => i !== itemIndex),
    );
  };

  const processChangeCargo = (orderIndex: number, orderItemIndex: number, v: CargoTemplateWithoutIdDTO) => {
    if (v) {
      form.setFieldValue(`orders[${orderIndex}].items[${orderItemIndex}].width`, v.width);
      form.setFieldValue(`orders[${orderIndex}].items[${orderItemIndex}].height`, v.height);
      form.setFieldValue(`orders[${orderIndex}].items[${orderItemIndex}].length`, v.length);
      form.setFieldValue(`orders[${orderIndex}].items[${orderItemIndex}].weight`, v.weight);
    }
  };

  return (
    <div className={'flex flex-col gap-2'}>
      {form.values?.orders?.map((order, orderIndex, orders) => {
        return (
          <OrderWrapper key={orderIndex}>
            <Anchor id={`order_${orderIndex}`} className={'ordersTabsContent'} />
            <COHeadline variant={'h3'} title={tr(`CreateTimeslotSidebar.oder`, `Order`)}></COHeadline>
            <Field label={tr(`CreateTimeslotSidebar.order`, `Order`)} errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].orderName`, tr)}>
              <InputAndButtonGrid>
                <InputTextarea value={order.orderName} style={{ width: '100%' }} onChange={e => form.setFieldValue(`orders[${orderIndex}].orderName`, e.target.value)} />
                {(orders.length > 1 || canBeEmpty == true) && (
                  <RowWithGap>
                    <DeleteButton onClick={() => deleteOrder(orderIndex)} />
                  </RowWithGap>
                )}
              </InputAndButtonGrid>
            </Field>
            {config.cargo?.visibility !== 'never' && (
              <>
                {findValidationMessageRaw(form.validationErrors, `orders[${orderIndex}].items`) && (
                  <COInlineMessage variant={'error'}>{findValidationMessageRaw(form.validationErrors, `orders[${orderIndex}].items`)?.message}</COInlineMessage>
                )}

                {order.items.map((item, itemIndex) => {
                  return (
                    <React.Fragment key={itemIndex}>
                      <div className={'grid grid-cols-[2fr,1fr] gap-2'}>
                        <div className={'flex-grow'}>
                          <Field
                            label={tr(`CreateTimeslotSidebar.manipulationUnit`, `Manipulation unit SKU`)}
                            errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].cargoTemplateCode`, tr)}
                          >
                            <InputSKU
                              fullWidth
                              skuCode={item.cargoTemplateCode}
                              onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].cargoTemplateCode`, v)}
                              onChangeCargoTemplate={v => processChangeCargo(orderIndex, itemIndex, v)}
                            />
                          </Field>
                        </div>
                        <div className={'flex-grow-0'}>
                          <Field
                            label={tr(`CreateTimeslotSidebar.amount`, `Amount`)}
                            errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].amount`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'ks'}
                              inputTextPosition={'right'}
                              value={item.amount}
                              onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].amount`, parseNumberOrUndefined(v))}
                              button={<DeleteButton onClick={() => deleteOrderItem(orderIndex, itemIndex)} />}
                            />
                          </Field>
                        </div>
                      </div>
                      <InputStackable stackable={item.stackable} onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].stackable`, v)} />
                      <InputNoteWithLink
                        note={item.itemNote}
                        onChanges={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].itemNote`, v)}
                        noteLabel={tr(`CreateTimeslotSidebar.orderItemNote`, `Order item note`)}
                      />

                      <VisibitityComponent
                        visibility={config.cargoDimensions.visibility}
                        switchComponent={<LinkWithIcon label={tr('OrdersComponent.rozmeryAHmotnost', 'Rozměry a hmotnost')} icon={<SvgIconPlus />} />}
                      >
                        <div className={'grid grid-cols-2 gap-2'}>
                          <Field
                            label={tr('OrdersComponent.sirkaJednotky', 'Šířka jednotky')}
                            required={config.cargoDimensions.items.width.required}
                            errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].width`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'cm'}
                              fullWidth
                              type={'number'}
                              value={item.width}
                              onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].width`, parseNumberOrUndefined(v))}
                            />
                          </Field>
                          <Field
                            label={tr('OrdersComponent.vyskaJednotky', 'Výška jednotky')}
                            required={config.cargoDimensions.items.height.required}
                            errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].height`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'cm'}
                              fullWidth
                              type={'number'}
                              value={item.height}
                              onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].height`, parseNumberOrUndefined(v))}
                            />
                          </Field>
                          <Field
                            label={tr('OrdersComponent.delka(celkova)', 'Délka (celková)')}
                            required={config.cargoDimensions.items.length.required}
                            errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].length`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'cm'}
                              fullWidth
                              type={'number'}
                              value={item.length}
                              onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].length`, parseNumberOrUndefined(v))}
                            />
                          </Field>
                          <Field
                            label={tr('OrdersComponent.hmotnost', 'Hmotnost')}
                            required={config.cargoDimensions.items.weight.required}
                            errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].weight`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'kg'}
                              fullWidth
                              type={'number'}
                              value={item.weight}
                              onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].weight`, parseNumberOrUndefined(v))}
                            />
                          </Field>
                        </div>
                      </VisibitityComponent>
                    </React.Fragment>
                  );
                })}
                <AddOrderItemButton onClick={() => addOrderItem(orderIndex)} />
              </>
            )}
            <VisibitityComponent
              visibility={config.cargoDetails?.visibility}
              switchComponent={<LinkWithIcon label={tr('OrdersComponent.informaceOZbozi', 'Informace o zboží')} icon={<SvgIconPlus />}></LinkWithIcon>}
            >
              <>
                <div className={'grid grid-cols-2 gap-2'}>
                  <Field
                    label={tr(`CreateTimeslotSidebar.productsInOrder`, `Products in order`)}
                    errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].orderAmount`, tr)}
                  >
                    <InputTextWithSuffix
                      suffix={'ks'}
                      type={'number'}
                      value={order.orderAmount}
                      onChange={v => form.setFieldValue(`orders[${orderIndex}].orderAmount`, parseNumberOrUndefined(v))}
                      fullWidth
                    />
                  </Field>
                  <Field
                    label={tr(`CreateTimeslotSidebar.productDescription`, `Description`)}
                    errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].orderDescription`, tr)}
                  >
                    <RingilInputText
                      value={order.orderDescription ? order.orderDescription : ''}
                      onChange={value => form.setFieldValue(`orders[${orderIndex}].orderDescription`, value)}
                    />
                  </Field>
                </div>
                <Field
                  label={tr('OrdersComponent.hodnotaZbozi', 'Hodnota zboží')}
                  required={config.cargoDetails.items.orderValue.required}
                  errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].orderValue`, tr)}
                >
                  <InputPriceWithCurrency
                    price={order.orderValue?.price}
                    currency={order.orderValue?.currency}
                    changePrice={v => form.setFieldValue(`orders[${orderIndex}].orderValue.price`, v)}
                    changeCurrency={v => form.setFieldValue(`orders[${orderIndex}].orderValue.currency`, v)}
                  />
                </Field>
              </>
            </VisibitityComponent>
            <InputNoteWithLink
              note={order.orderNote}
              onChanges={v => form.setFieldValue(`orders[${orderIndex}].orderNote`, v)}
              noteLabel={tr(`CreateTimeslotSidebar.orderNote`, `Order note`)}
            />

            {/*<HorizontalLine variant={'normal'} />*/}
            {config.totalWeight.render === true && (
              <Field
                readOnly={true}
                label={tr(`CreateTimeslotSidebar.totalWeight`, `Total weight`)}
                errorMessage={findValidationMessage(form.validationErrors, `totalWeight`, tr)}
              >
                <InputTextWithSuffix
                  suffix={'kg'}
                  readOnly={true}
                  value={order.items.reduce((acc, item) => acc + (item.weight || 0), 0)}
                  onChange={v => {}}
                  fullWidth
                  type={'number'}
                />
              </Field>
            )}

            {config.unloadingLocation.render === true && (
              <>
                <COHeadline title={'Vyložit objednávku'} variant={'h4'} icon={<SvgLoadDown />} />
                <Dropdown
                  value={order.unloadingLocationId}
                  options={allUnloadingLocations}
                  optionLabel={'name'}
                  optionValue={'id'}
                  onChange={e =>
                    form.useProduce(draft => {
                      draft.orders[orderIndex].unloadingLocationId = e.value;
                    })
                  }
                />
              </>
            )}
            {orderIndex == orders.length - 1 && <AddOrderButton onClick={() => addOrder()} />}
          </OrderWrapper>
        );
      })}
    </div>
  );
};

export default OrdersComponent;
