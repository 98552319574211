import {
  AttachmentsUploadedOutDTO,
  CGV2LocationOutDTO,
  ExpeditionTypeEnum,
  InvoiceStateEnum,
  LovDTO,
  SaveExpeditionLocationRequestInDTO,
  TransportationTypeEnum,
} from '@api/logsteo-api.v2.tsx';
import { CargoType } from '@components/framework/input/InputCargoType/InputCargoType.tsx';
import { RebillingType } from '@components/framework/tags/InternalInfoRebillingTag.tsx';
import { CreateDistributionOrAssignData } from '@app/pages/expedition/create/panels/CreateDistributionOrAssign.tsx';

/*export interface ExpeditionLocation {
  locationId: string;
  name: string;
  zipCode: string;
  city: string;
  streetNr: string;
  countryCode3: string;
}*/

interface ExpeditionLocation extends CGV2LocationOutDTO {}

export enum LocalityEntryModeEnum {
  MANUALLY = 'MANUALLY',
  FROM_LOCATIONS = 'FROM_LOCATIONS',
}

export interface ManualAddress {
  name?: string;
  postalCode?: string;
  countryCode3?: string;
  city?: string;
  streetNr?: string;
  mainContactPerson?: string;
  mainContactPhone?: string;
  mainContactEmail?: string;
  notifyContactPerson?: boolean;
  additionalPersons?: AdditionalPerson[];
}

export interface ExpeditionLocality extends SaveExpeditionLocationRequestInDTO {
  localityMode: LocalityEntryModeEnum;
  location: ExpeditionLocation;
  manualAddress: ManualAddress;
  // this is using for animation
  id: string;
}

export interface AdditionalPerson {
  name: string;
  email: string;
}

export interface NewExpeditionForm {
  locations: ExpeditionLocality[];
  distribution: CreateDistributionOrAssignData;
  specialRequirements?: string[];
  carrierNote?: string;
  transportationType?: TransportationTypeEnum;
  expeditionType?: ExpeditionTypeEnum;
  loadingTypes?: string[];
  truckTypeCode?: string;
  cargoType?: CargoType;
  persons?: LovDTO[];
  person?: LovDTO;
  notifyByRole?: boolean;
  invoiceState?: InvoiceStateEnum;
  disponentPerson?: LovDTO;
  pairingSymbol?: string;
  internalNote?: string;
  rebiling?: RebillingType;
  attachments?: AttachmentsUploadedOutDTO[];
}
