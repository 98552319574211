import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import * as yup from 'yup';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon.tsx';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import SvgCalendar from '@components/framework/icons/Calendar.tsx';
import SvgForExpedition from '@icons/ForExpedition.tsx';
import { HorizontalLine, RowWithSpaceBetween } from '@components/styles.tsx';
import RingilInputSwitch from '@components/framework/input/RingilInputSwitch/RingilInputSwitch.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import SvgRepeatIcon from '@app/icons/admin/icon/RepeatIcon.tsx';
import { Clickable } from '@components/ringil3/styles.tsx';
import { DayEnum } from '@api/logsteo-api.v2.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import SvgGrayInfo from '@components/ringil3/icons/GrayInfo.tsx';

interface ComponentProps {
  expeditionId: string;
  applicationId: string;
}

interface Form {}

export const useCreateDuplicateExpeditionSidebar = () => {
  const [visible, setVisible] = useState(false);

  const DuplicateExpeditionSidebar: React.FC<ComponentProps> = ({ expeditionId, applicationId }) => {
    const {} = useContext(ApiContext);
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    const save = (data: Form) => {
      console.log('save', data);
    };
    const form = useForm<Form>(yup.object().shape({}), {}, d => save(d), false, false);

    const createNewSlot = (days: number) => {};
    const createNewSlotNext = (day: DayEnum) => {};

    return (
      <>
        <RingilSidebar
          visible={visible}
          heading={'Duplikace přepravy'}
          onBack={() => setVisible(false)}
          onContinue={() => {
            form.validateAndSend();
          }}
        >
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon label={'Výchozí přeprava'} valueText={applicationId} icon={<SvgForExpedition />} />
            <VerticalFieldAndValueWithIcon label={'Začínající'} valueText={'4. 12. 2024 | 12:00 - 12:55'} icon={<SvgCalendar />} />
            <HorizontalLine variant={'tertiary'} />
            <RingilInputSwitch label={'Duplikovat včetně termínů rezervací'} value={false} onChange={v => {}} />
            <RingilInputSwitch label={'Kopírovat přílohy z přepravy (FV)'} value={false} onChange={v => {}} />
            <HorizontalLine variant={'tertiary'} />
            <COHeadline title={'Duplikovat v následující termínech'} variant={'h3'} />
            <COHeadline variant={'h4'} icon={<SvgRepeatIcon />} title={'Vytvořit novou'} />
            <div className={'flex flex-col gap-1.5'}>
              <div className={'flex flex-row gap-1.5'}>
                <Clickable onClick={() => createNewSlot(1)}>{tr(`duplicate.1Den`, `1 den`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlot(7)}>{tr(`duplicate.7Dnu`, `7 dnu`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlot(14)}>{tr(`duplicate.14Dni`, `14 dni`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlot(21)}>{tr(`duplicate.21Dnu`, `21 dnu`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlot(30)}>{tr(`duplicate.30Dni`, `30 dni`)}</Clickable>
              </div>
              {/*<Clickable onClick={() => setCustomDate(true)}>{tr(`duplicate.vlastni`, `Vlastni`)}</Clickable>*/}
              <div className={'flex flex-row gap-1.5'}>
                <Clickable onClick={() => createNewSlotNext(DayEnum.MONDAY)}>
                  <div>{tr(`duplicate.nextMonday`, `Pristi pondeli`)}</div>
                </Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.TUESDAY)}>{tr(`duplicate.tuesday`, `Utery`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.WEDNESDAY)}>{tr(`duplicate.wednesday`, `Streda`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.THURSDAY)}>{tr(`duplicate.thursday`, `Ctvrtek`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.FRIDAY)}>{tr(`duplicate.friday`, `Patek`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.SATURDAY)}>{tr(`duplicate.saturday`, `Sobota`)}</Clickable>
                <span>|</span>
                <Clickable onClick={() => createNewSlotNext(DayEnum.SUNDAY)}>{tr(`duplicate.sunday`, `Nedele`)}</Clickable>
              </div>
            </div>
            <COInlineMessage variant={'info'} icon={<SvgGrayInfo />}>
              Všechny termíny na ostatních lokalitách budou posunuté o stejný časový interval jako je nastavený ve výchozí přepravě.{' '}
            </COInlineMessage>
          </div>
        </RingilSidebar>
      </>
    );
  };

  return { DuplicateExpeditionSidebar, visible, setVisible };
};
