import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import InputResponsiblePerson from '@components/framework/input/InputResponsiblePerson/InputResponsiblePerson.tsx';
import { LovDTO } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  required?: boolean;
  readonly?: boolean;
  disponentPerson: LovDTO;
  onChange: (responsiblePerson: LovDTO) => void;
}

const InternalInfoDisponentTag: React.FC<ComponentProps> = ({ readonly, required, disponentPerson, onChange }) => {
  const [internalValue, setInternalValue] = useState(disponentPerson);
  const { tr } = useTranslationLgs();

  const renderText = (disponentPerson: LovDTO) => {
    return disponentPerson ? disponentPerson.name : null;
  };

  return (
    <>
      <ButtonTag
        label={'Disponent'}
        required={required}
        readonly={readonly}
        value={renderText(disponentPerson)}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
      >
        <InputResponsiblePerson responsiblePerson={internalValue} onChange={setInternalValue} />
      </ButtonTag>
    </>
  );
};

export default InternalInfoDisponentTag;
