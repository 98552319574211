import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { ApiContext } from '@api/api.tsx';
import { LovTransportationTypeOutDTO, TransportationTypeEnum } from '@api/logsteo-api.v2.tsx';
import { Dropdown } from 'primereact/dropdown';
import SvgTransportationTypeAirplane from '@components/framework/icons/TransportationTypes/TransportationTypeAirplane.tsx';
import SvgTransportationTypeBoat from '@components/framework/icons/TransportationTypes/TransportationTypeBoat.tsx';
import SvgTransportationTypeTrain from '@components/framework/icons/TransportationTypes/TransportationTypeTrain.tsx';
import SvgTransportationTypeTruck from '@components/framework/icons/TransportationTypes/TransportationTypeTruck.tsx';

interface ComponentProps {
  transportationType?: TransportationTypeEnum;
  readOnly?: boolean;
  required?: boolean;
  onChange: (code: TransportationTypeEnum) => void;
}

const TransportationTypeTag: React.FC<ComponentProps> = ({ transportationType, readOnly, required, onChange }) => {
  const { tr } = useTranslationLgs();
  const [internalValue, setInternalValue] = useState<TransportationTypeEnum>(transportationType);

  const { lovTransportationTypes } = useContext(ApiContext);
  const [transportationTypes, setTransportationTypes] = useState<LovTransportationTypeOutDTO[]>([]);

  useEffect(() => {
    lovTransportationTypes(d => {
      setTransportationTypes(d);
    });
  }, []);

  const renderText = (code: string) => {
    const item = transportationTypes.find(i => i.transportationType === code);
    return item ? item.name : null;
  };

  const pickIcon = (item: TransportationTypeEnum) => {
    switch (item) {
      case TransportationTypeEnum.AIR:
        return <SvgTransportationTypeAirplane />;
      case TransportationTypeEnum.BOAT:
        return <SvgTransportationTypeBoat />;
      case TransportationTypeEnum.TRAIN:
        return <SvgTransportationTypeTrain />;
      case TransportationTypeEnum.GROUND:
        return <SvgTransportationTypeTruck />;
      default:
        return null;
    }
  };

  return (
    <>
      <ButtonTag
        label={tr('TransportationTypeTag.typPrepravy', 'Typ přepravy')}
        required={required}
        readonly={readOnly}
        value={renderText(transportationType)}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        icon={pickIcon(transportationType)}
      >
        <Dropdown
          options={transportationTypes.map(item => ({ code: item.transportationType, label: item.name }))}
          value={internalValue}
          optionLabel="label"
          optionValue={'code'}
          onChange={e => {
            setInternalValue(e.target.value);
          }}
        />
      </ButtonTag>
    </>
  );
};

export default TransportationTypeTag;
