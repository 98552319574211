import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import InputResponsiblePerson from '@components/framework/input/InputResponsiblePerson/InputResponsiblePerson.tsx';
import { LovDTO } from '@api/logsteo-api.v2.tsx';
import InputResponsiblePersons from '@components/framework/input/InputResponsiblePerson/InputResponsiblePersons.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import { InputDocumentTypeEnum } from '@components/features/eTransport/input/ETransportInputDocumentType.tsx';
import { formatLocalDate, isNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  required?: boolean;
  readonly?: boolean;
  persons: LovDTO[];
  onChange: (responsiblePersons: LovDTO[]) => void;
}

const InternalInfoNotifyTag: React.FC<ComponentProps> = ({ readonly, required, persons, onChange }) => {
  const [internalValue, setInternalValue] = useState(persons);
  const { tr } = useTranslationLgs();

  const renderText = (persons: LovDTO[]) => {
    if (isNullOrUndefined(persons) || persons.length === 0) {
      return null;
    }
    return <MultipleValues values={persons.map(t => t.name)}></MultipleValues>;
  };

  return (
    <>
      <ButtonTag
        label={tr('InternalInfoNotifyTag.notifikovat', 'Notifikovat')}
        required={required}
        readonly={readonly}
        value={renderText(persons)}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
      >
        <InputResponsiblePersons responsiblePersons={internalValue} onChange={setInternalValue} />
      </ButtonTag>
    </>
  );
};

export default InternalInfoNotifyTag;
