import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { ValidationError } from 'yup';
import { hasValidationProblemForPrefix } from '@utils/validation.tsx';
import SvgNumber from '@icons/Number.tsx';
import { InvoiceStateEnum } from '@api/logsteo-api.v2.tsx';
import { Dropdown } from 'primereact/dropdown';
import SvgIconFile from '@components/framework/icons/IconFile.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';

interface ComponentProps {
  value: InvoiceStateEnum;
  onChange: (value: InvoiceStateEnum) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

const InternalInfoInvoiceState: React.FC<ComponentProps> = ({ value, onChange, readonly, validationErrors, validationPrefix }) => {
  const [internalValue, setInternalValue] = useState<InvoiceStateEnum>(value);
  const { tr } = useTranslationLgs();

  const printValue = (value: InvoiceStateEnum) => {
    if (isNullOrUndefined(value)) return null;

    return tr(`InvoiceStateEnum.${value}`, value);
  };

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  return (
    <>
      <ButtonTag
        label={'Stav fakturace'}
        icon={<SvgIconFile />}
        required={false}
        value={printValue(value)}
        readonly={readonly}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
      >
        <>
          <Field label={'Stav fakturace'}>
            <Dropdown
              value={internalValue}
              options={[InvoiceStateEnum.NON_INVOICED, InvoiceStateEnum.INVOICED]}
              showClear={true}
              style={{ width: '100%' }}
              valueTemplate={e => <div>{tr(`InvoiceStateEnum.${e}`, e)}</div>}
              itemTemplate={e => <div>{tr(`InvoiceStateEnum.${e}`, e)}</div>}
              onChange={e => {
                setInternalValue(e.value);
              }}
            />
          </Field>
        </>
      </ButtonTag>
    </>
  );
};

export default InternalInfoInvoiceState;
