import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgSelectedCarrier from '@components/ringil3/icons/SelectedCarrier.tsx';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import InputInheriterCompany from '@components/obsolete/Form/InputEntityOwner/InputInheriterCompany.tsx';
import { CompanyModuleEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  carrierName: string;
  carrierId: string;
  companyLocationCustomerId: string;
  onChange?: (carrierId: string) => void;
}

interface Form {
  carrierId: string;
}

const SelectedCarrierTag: React.FC<ComponentProps> = ({ carrierName, carrierId, companyLocationCustomerId, onChange }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const [open, setOpen] = useState(false);

  const form = useForm<Form>(yup.object().shape({}), { carrierId }, v => save(v), false, false);

  const save = (v: Form) => {
    setOpen(false);
    if (onChange != null) onChange(v.carrierId);
  };

  const canEdit = loggedUser.companyId == companyLocationCustomerId;

  const pane = (
    <>
      <VerticalFieldAndValue label={tr(`CreateTimeslotSidebar.carrier`, `Carrier`)} labelMode={'normal'}>
        <InputInheriterCompany
          companyId={companyLocationCustomerId}
          inheriterId={form.values.carrierId}
          onChange={v => form.setFieldValue(form.names.carrierId, v)}
          module={CompanyModuleEnum.TIMESLOTS}
        />
      </VerticalFieldAndValue>
    </>
  );

  return (
    <>
      <ButtonTag
        label={tr(`SelectedCarrierTag.title`, `Selected carrier`)}
        value={carrierName}
        icon={<SvgSelectedCarrier />}
        actionPane={pane}
        actionIcon={canEdit ? <SvgTagEdit /> : undefined}
        onSave={() => {
          form.validateAndSend();
        }}
      />
    </>
  );
};

export default SelectedCarrierTag;
