import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { ColWithGap, RowWithGap, RowWithSpaceBetween } from '@components/styles';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge';
import useForm from '@hooks/useForm/useForm';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { ActionTypeEnum, ActionTypesEnum, EntityTypeEnum, ReservationActionTypesEnum } from '@api/logsteo-api.v2';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import { Anchor, ColOdd, HorizontalLine, InputAndButtonGrid, OrderWrapper, TwoColGridWithoutGap } from '@components/ringil3/styles';
import { findValidationMessage } from '@utils/validation';
import InputTextWithSuffix from '@components/ringil3/Input/InputTextWithSuffix/InputTextWithSuffix';
import { isNotNullOrUndefined } from '@utils/utils';
import InputManipulationType from '@components/ringil3/Input/InputManipulationType/InputManipulationType';
import OrderTabs, { TabProps } from '@components/ringil3/RingilTabs/OrderTabs';
import { InputTextarea } from 'primereact/inputtextarea';
import DeleteButton from '@components/ringil3/Buttons/DeleteButton';
import InputSKU from '@components/ringil3/Input/InputSKU/InputSKU';
import InputStackable from '@components/ringil3/Input/InputStackable/InputStackable';
import InputNoteWithLink from '@components/ringil3/Input/InputNoteWithLink/InputNoteWithLink';
import AddOrderItemButton from '@components/ringil3/Features/Expedition/AddOrderItemButton/AddOrderItem';
import { InputText } from 'primereact/inputtext';
import InputPrice from '@components/ringil3/Input/InputPrice/InputPrice';
import AddOrderButton from '@components/ringil3/Features/Expedition/AddOrderButton/AddOrderButton';
import { emptyOrder, Order, orderSchema } from '@components/framework/orders/common';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  reservationId: string;
  applicationId: string;
  onRefresh: () => void;
}

interface Form {
  actionTypes: ReservationActionTypesEnum[];
  orders: Order[];
}

export const useCreateEditReservationOrdersSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditReservationOrdersSidebar: React.FC<ComponentProps> = ({ reservationId, applicationId, onRefresh }) => {
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [apiRequest, setApiRequest] = useState(false);
    const { getReservationDetail, updateReservationOrders } = useContext(ApiContext);

    useEffect(() => {
      const ac = new AbortController();

      getReservationDetail(
        reservationId,
        d => {
          if (ac.signal.aborted) return;

          form.overwriteValues({
            actionTypes: d.reservationActionTypes,
            orders:
              d.orders.length > 0
                ? d.orders.map(
                    t =>
                      ({
                        orderName: t.orderName,
                        orderValue: t.orderValue,
                        orderAmount: t.orderAmount,
                        orderDescription: t.orderDescription,
                        orderNote: t.orderNote,
                        items: t.items.map(i => ({
                          amount: i.amount,
                          cargoTemplateCode: i.cargoTemplate?.code,
                          stackable: i.stackable,
                          itemNote: i.itemNote,
                        })),
                      } as Order),
                  )
                : [
                    {
                      items: [],
                      orderAmount: null,
                      orderDescription: null,
                      orderName: null,
                      orderNote: null,
                      orderValue: null,
                    },
                  ],
          });
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
      return () => {
        ac.abort();
      };
    }, [reservationId]);

    const form = useForm<Form>(yup.object().shape({ orders: orderSchema }), null, d => save(d), false, false, null, 'scroll');

    const mapOrder = (order: Order) => {
      return {
        orderName: order.orderName,
        orderValue: order.orderValue,
        cargoAmount: order.orderAmount,
        cargoDescription: order.orderDescription,
        orderNote: order.orderNote,
        items: order.items.map(i => ({
          amount: i.amount,
          cargoTemplateCode: i.cargoTemplateCode,
          stackable: i.stackable,
          note: i.itemNote,
        })),
      };
    };

    const save = (data: Form) => {
      updateReservationOrders(
        reservationId,
        {
          orders: data.orders.map(mapOrder),
          actionTypes: data.actionTypes.map(t => ActionTypesEnum[t]),
        },
        () => {
          setVisible(false);
          onRefresh();
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
    };

    const addOrder = () => {
      form.setFieldValue(form.names.orders, [...form.values.orders, emptyOrder(false)]);
    };

    const deleteOrder = (orderIndex: number) => {
      form.setFieldValue(
        form.names.orders,
        form.values.orders.filter((_, i) => i !== orderIndex),
      );
    };

    const addOrderItem = (orderIndex: number) => {
      form.setFieldValue(`orders[${orderIndex}].items`, [
        ...form.values.orders[orderIndex].items,
        { itemNote: null, amount: null, cargoTemplateCode: null, stackable: false },
      ]);
    };

    const deleteOrderItem = (orderIndex: number, itemIndex: number) => {
      form.setFieldValue(
        `orders[${orderIndex}].items`,
        form.values.orders[orderIndex].items.filter((_, i) => i !== itemIndex),
      );
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          heading={
            <RowWithSpaceBetween>
              <div>{tr('EditReservationOrdersSidebar.title', 'Edit reservation orders')}</div>
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map(t => t.path)).size} />
            </RowWithSpaceBetween>
          }
          onContinue={() => {
            form.validateAndSend();
          }}
        >
          {isNotNullOrUndefined(form.values) && (
            <>
              <BackendValidationComponent />
              <ColWithGap>
                <VerticalFieldAndValueWithIcon
                  label={tr(`EditReservationSlotSidebar.forReservation`, `For reservation`)}
                  valueText={applicationId}
                  iconPath={'/images/icons/ringil3/forExpedition.svg'}
                />
                <HorizontalLine variant={'tertiary'} />
                <COHeadline variant={'h3'} title={tr(`EditReservationOrdersSidebar.cargo`, `Cargo`)}></COHeadline>
                <VerticalFieldAndValue label={tr(`EditReservationOrdersSidebar.manipulationType`, `Manipulation type`)} labelMode={'normal'}>
                  <InputManipulationType
                    loadingTypes={form.values.actionTypes}
                    onChange={v => {
                      form.setFieldValue(form.names.actionTypes, v);
                    }}
                  />
                </VerticalFieldAndValue>
                <OrderTabs
                  tabs={form.values.orders.map(
                    (_, oIndex) =>
                      ({
                        label: `${oIndex + 1}.`,
                        icon: null,
                        active: false,
                        anchorId: `order_${oIndex}`,
                        className: 'ordersTabs',
                      } as TabProps),
                  )}
                  contentSelectorClassName={'ordersTabsContent'}
                ></OrderTabs>
                <ColOdd>
                  {form.values.orders.map((order, orderIndex, orders) => {
                    return (
                      <OrderWrapper key={orderIndex}>
                        <Anchor id={`order_${orderIndex}`} className={'ordersTabsContent'} />
                        <COHeadline variant={'h3'} title={tr(`EditReservationOrdersSidebar.oder`, `Order`)}></COHeadline>
                        <VerticalFieldAndValue
                          label={tr(`EditReservationOrdersSidebar.order`, `Order`)}
                          labelMode={'normal'}
                          helpText={tr(`EditReservationOrdersSidebar.orderHelpText`, `HelpText`)}
                          errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].orderName`, tr)}
                        >
                          <InputAndButtonGrid>
                            <InputTextarea
                              value={order.orderName}
                              style={{ width: '100%' }}
                              onChange={e => form.setFieldValue(`orders[${orderIndex}].orderName`, e.target.value)}
                            />
                            {form.values.orders.length > 1 && (
                              <RowWithGap>
                                <DeleteButton onClick={() => deleteOrder(orderIndex)} />
                              </RowWithGap>
                            )}
                          </InputAndButtonGrid>
                        </VerticalFieldAndValue>
                        {order.items.map((item, itemIndex) => {
                          return (
                            <React.Fragment key={itemIndex}>
                              <TwoColGridWithoutGap>
                                <VerticalFieldAndValue
                                  label={tr(`EditReservationOrdersSidebar.manipulationUnit`, `Manipulation unit SKU`)}
                                  labelMode={'normal'}
                                  errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].cargoTemplateCode`, tr)}
                                >
                                  <InputSKU
                                    skuCode={item.cargoTemplateCode}
                                    onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].cargoTemplateCode`, v)}
                                  />
                                </VerticalFieldAndValue>
                                <VerticalFieldAndValue
                                  label={tr(`EditReservationOrdersSidebar.amount`, `Amount`)}
                                  labelMode={'normal'}
                                  errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].amount`, tr)}
                                >
                                  <InputTextWithSuffix
                                    suffix={'ks'}
                                    inputTextPosition={'right'}
                                    value={item.amount}
                                    onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].amount`, v)}
                                    button={<DeleteButton onClick={() => deleteOrderItem(orderIndex, itemIndex)} />}
                                  />
                                </VerticalFieldAndValue>
                              </TwoColGridWithoutGap>
                              <InputStackable stackable={item.stackable} onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].stackable`, v)} />
                              <InputNoteWithLink
                                note={item.itemNote}
                                onChanges={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].itemNote`, v)}
                                noteLabel={tr(`EditReservationOrdersSidebar.orderItemNote`, `Order item note`)}
                              />
                              {/*<HorizontalLine variant={'tertiary'} />*/}
                            </React.Fragment>
                          );
                        })}
                        <AddOrderItemButton onClick={() => addOrderItem(orderIndex)} />
                        {/*<TwoColGridWithoutGap>
                          <VerticalFieldAndValue
                            label={tr(`EditReservationOrdersSidebar.productsInOrder`, `Products in order`)}
                            labelMode={'normal'}
                          >
                            <InputTextWithSuffix
                              suffix={'ks'}
                              value={order.orderAmount}
                              onChange={(v) => form.setFieldValue(`orders[${orderIndex}].orderAmount`, v)}
                            />
                          </VerticalFieldAndValue>
                          <VerticalFieldAndValue
                            label={tr(`EditReservationOrdersSidebar.productDescription`, `Description`)}
                            labelMode={'normal'}
                          >
                            <InputText
                              style={{ height: '39px' }}
                              value={order.orderDescription ? order.orderDescription : ''}
                              onChange={(e) =>
                                form.setFieldValue(`orders[${orderIndex}].orderDescription`, e.target.value)
                              }
                            />
                          </VerticalFieldAndValue>
                        </TwoColGridWithoutGap>
                        <InputPrice
                          priceLabel={tr(`EditReservationOrdersSidebar.goodsValue`, `Goods value`)}
                          required={false}
                          price={order.orderValue}
                          onPriceChange={(v) => form.setFieldValue(`orders[${orderIndex}].orderValue`, v)}
                          onCurrencyChange={(v) => form.setFieldValue(`orders[${orderIndex}].orderValueCurrency`, v)}
                          currency={order.orderValueCurrency}
                        />*/}
                        {/*                    <VerticalFieldAndValue
                      label={tr(`EditReservationOrdersSidebar.totalWeight`, `Total weight`)}
                      labelMode={'normal'}
                      readOnly={true}
                    >
                      <InputTextWithSuffix suffix={'kg'} value={'3 3453'} onChange={(v) => {}} />
                    </VerticalFieldAndValue>*/}
                        <InputNoteWithLink
                          note={order.orderNote}
                          onChanges={v => form.setFieldValue(`orders[${orderIndex}].orderNote`, v)}
                          noteLabel={tr(`EditReservationOrdersSidebar.orderNote`, `Order note`)}
                        />
                        {/*<HorizontalLine variant={'normal'} />*/}
                        {orderIndex == orders.length - 1 && <AddOrderButton onClick={() => addOrder()} />}
                      </OrderWrapper>
                    );
                  })}
                </ColOdd>
              </ColWithGap>
            </>
          )}
        </RingilSidebar>
      </>
    );
  };

  return { EditReservationOrdersSidebar, setVisible, visible };
};
