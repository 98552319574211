import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import InputResponsiblePerson from '@components/framework/input/InputResponsiblePerson/InputResponsiblePerson.tsx';
import { LovDTO } from '@api/logsteo-api.v2.tsx';
import InputResponsiblePersons from '@components/framework/input/InputResponsiblePerson/InputResponsiblePersons.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import { InputDocumentTypeEnum } from '@components/features/eTransport/input/ETransportInputDocumentType.tsx';
import { formatLocalDate, isNullOrUndefined } from '@utils/utils.tsx';
import { InputSwitch } from 'primereact/inputswitch';
import COText from '@app/pages/COText/COText.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';

interface ComponentProps {
  notifyRoleName: string;
  isActive: boolean;
  onChange: (isActive: boolean) => void;
  required?: boolean;
  readonly?: boolean;
}

const InternalInfoNotifyByRoleTag: React.FC<ComponentProps> = ({ readonly, required, notifyRoleName, onChange, isActive }) => {
  const [internalValue, setInternalValue] = useState(isActive);
  const { tr } = useTranslationLgs();
  const { lovUsersWithSpecificRole } = useContext(ApiContext);
  const [usersInRole, setUsersInRole] = useState<LovDTO[]>();

  useEffect(() => {
    lovUsersWithSpecificRole('', notifyRoleName, d => {
      setUsersInRole(d);
    });
  }, []);

  return (
    <>
      <ButtonTag
        label={tr('InternalInfoNotifyByRoleTag.notifikovatDleRole', 'Notifikovat dle role')}
        required={required}
        readonly={readonly}
        value={isActive ? notifyRoleName : null}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        popupWidth={'633px'}
      >
        <div className={'flex flex-col gap-6'}>
          <div className={'flex flex-row gap-4'}>
            <InputSwitch checked={internalValue} onChange={e => setInternalValue(e.target.value)} />
            <COText label={tr(`InternalInfoNotifyByRoleTag.notifyByRole`, `Spustit notifikace pro {{notifyRoleName}}`, { notifyRoleName })} />
          </div>
          {internalValue === true && (
            <COInlineMessage variant={'info'}>
              <div className={'flex flex-col gap-4'}>
                <COText
                  label={tr(`InternalInfoNotifyByRoleTag.roleNotifyRoleNameUsersWithComma`, `Role {{notifyRoleName}}: {{usersWithComma}}`, {
                    notifyRoleName,
                    usersWithComma: usersInRole?.map(t => t.name).join(', '),
                  })}
                />

                <COText
                  label={tr(
                    'InternalInfoNotifyByRoleTag.oZmenachBudouNadaleInformovaniIKolegoveUvedeniVBezneNotifikaciTetoAvizace',
                    'O změnách budou nadále informováni i kolegové uvedení v běžné notifikaci této avizace.',
                  )}
                />
              </div>
            </COInlineMessage>
          )}
        </div>
      </ButtonTag>
    </>
  );
};

export default InternalInfoNotifyByRoleTag;
