import { useContext, useEffect, useState } from 'react';
import { AnySchema, ValidationError } from 'yup';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { getIn, setIn } from '@utils/forms.ts';
import useTranslationLgs from '../i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { validate } from '@utils/validation.tsx';
import { useStateCallback2 } from '@hooks/useStateCallback/useStateCallback2.tsx';
import { produce } from 'immer';

interface FormValues {
  [key: string]: any;
}

export declare type FormikErrors<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
    ? Values[K][number] extends object
      ? FormikErrors<Values[K][number]>[] | string | string[]
      : string | string[]
    : Values[K] extends object
    ? FormikErrors<Values[K]>
    : string;
};

export const proxiedPropertiesOf = <TObj,>(obj?: TObj) => {
  return new Proxy(
    {},
    {
      get: (_, prop) => prop,
      set: () => {
        throw Error('Set not supported');
      },
    },
  ) as {
    [P in keyof TObj]?: P;
  };
};

const useForm = <T extends FormValues>(
  validationScheme: any | ((formData: T) => AnySchema),
  initialValues: T,
  onSuccessMethod: (values: T, onSuccess?: () => void) => void,
  validateOnChange: boolean,
  validateOnMount: boolean,
  runValidations: () => void = () => {},
  errorMode: 'toast' | 'scroll' | 'none' = 'toast',
) => {
  const [formData, setFormData] = useStateCallback2<T>(initialValues);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation('common');
  const [errors, setErrors] = useState<FormikErrors<T>>({});
  const [calledSetValues, setCalledSetValues] = useState(false);
  const [ready, setReady] = useState(true);

  useEffect(() => {
    if (validateOnMount) {
      validateForm(formData);
    }
  }, []);

  const { tr } = useTranslationLgs();
  const { showToastMessage } = useContext(ToastContext);

  useEffect(() => {
    if (validationErrors && validationErrors.length > 0) {
      if (errorMode === 'toast')
        showToastMessage(
          tr('CreateSupplierNotification.errorOnThePage', 'Error on the page'),
          tr(`CreateSupplierNotification.errorOnThePageDetail`, `There are errors on the page that prevent the form from being submitted`),
          'error',
        );
    } else if (errorMode === 'scroll') {
      const element = document.querySelector('.hasError');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [validationErrors]);

  const names = proxiedPropertiesOf<T>();

  const validateForm = (formData: T) => {
    const validationErrors = validate(validationScheme, formData, { formData }) || [];
    /*console.log('validationErrors', validationErrors);*/
    setIsValid(validationErrors.length == 0);
    setValidationErrors(validationErrors);
    setErrors(convertToErrors(validationErrors));
    if (runValidations) runValidations();
  };

  const validateAndSend = (validatingData: any = formData) => {
    const validationErrors = validate(validationScheme, validatingData, { formData }) || [];
    if (runValidations) runValidations();
    /*console.log('validationErrors', validationErrors, validatingData);*/
    setValidationErrors(validationErrors);
    setErrors(convertToErrors(validationErrors));
    setIsValid(validationErrors.length == 0);
    if (validationErrors.length === 0) onSuccessMethod(formData);
  };
  const validateAndSendWithSuccess = (onSuccess: () => void, validatingData: any = formData) => {
    const validationErrors = validate(validationScheme, validatingData, { formData }) || [];
    if (runValidations) runValidations();
    /*console.log('validationErrors', validationErrors, validatingData);*/
    setValidationErrors(validationErrors);
    setErrors(convertToErrors(validationErrors));
    setIsValid(validationErrors.length == 0);
    if (validationErrors.length === 0) onSuccessMethod(formData, onSuccess);
  };

  const find = (path: string, startsWith: boolean = false) => {
    const error = validationErrors.find(e => (startsWith ? e.path.startsWith(path) : e.path === path));
    if (isNullOrUndefined(error)) return undefined;
    return t(`validation.${error.type}`);
  };

  const clear = () => {
    setValidationErrors([]);
  };

  // use immer produce
  const useProduce = (fn: (draft: T) => void) => {
    const nextValue = produce(formData, fn);
    overwriteValues(nextValue);
  };

  const setFieldValue = (fieldName: string, value: any, onSet: () => void) => {
    /*console.log('setFieldValue', fieldName, value, onSet);*/
    setFormData(
      d => {
        return setIn(isNullOrUndefined(d) ? {} : d, fieldName, value);
      },
      state => {
        /*console.log('setFieldValue - onChanged', state);
        if (isNotNullOrUndefined(onSet)) {
          console.log('onSet - active');
        } else {
          console.log('onSet - not active');
        }*/
        if (validateOnChange) validateForm(state);
        if (onSet) {
          onSet();
        }
      },
    );
  };

  const setValues = (partialValues: FormValues) => {
    setFormData(
      d => {
        return { ...d, ...partialValues };
      },
      state => {
        if (validateOnChange) {
          validateForm(state);
        }
        setCalledSetValues(true);
      },
    );
  };

  const overwriteValues = (values: T) => {
    setFormData(
      v => values,
      state => {},
    );
  };

  function convertToErrors<Values>(yupError: ValidationError[]): FormikErrors<Values> {
    let errors: FormikErrors<Values> = {};
    yupError.forEach(yupError => {
      if (yupError.inner.length === 0) {
        errors = {
          ...errors,
          ...setIn(errors, yupError.path, yupError.message),
        };
      }
      for (const err of yupError.inner) {
        if (!getIn(errors, err.path)) {
          errors = { ...errors, ...setIn(errors, err.path, err.message) };
        }
      }
    });
    return errors;
  }

  const getSubForm = <SubT extends FormValues>(path: string): Form<SubT> => {
    // Filtrování errors pro aktuální SubForm
    const subErrors: FormikErrors<SubT> = Object.keys(errors)
      .filter(key => key.startsWith(path))
      .reduce((acc, key) => {
        const subKey = key.replace(`${path}.`, '');
        return { ...acc, [subKey]: errors[key] };
      }, {} as FormikErrors<SubT>);

    const validateSubForm = (subFormData: SubT) => {
      const fullFormData = setIn(formData, path, subFormData);
      validateForm(fullFormData);
    };

    return {
      validationErrors,
      validateAndSend: () => validateSubForm(getIn(formData, path)),
      validateAndSendWithSuccess: (onSuccess: () => void) => {
        const subData = getIn(formData, path);
        const validationErrors = validate(validationScheme, subData, { formData }) || [];
        setValidationErrors(validationErrors);
        if (validationErrors.length === 0) onSuccessMethod(formData, onSuccess);
      },
      clear,
      find: (subPath: string) => find(`${path}.${subPath}`),
      isValid,
      setValues: (partialValues: Partial<SubT>) => {
        const newValue = { ...getIn(formData, path), ...partialValues };
        setFieldValue(path, newValue, () => {});
      },
      setFieldValue: (subFieldName: string, value: any, onSet: () => void) => {
        /*console.log('setFieldValue-subform', subFieldName, value, onSet);*/
        setFieldValue(`${path}.${subFieldName}`, value, onSet);
      },
      values: getIn(formData, path) as SubT,
      errors: subErrors,
      validateForm: validateSubForm,
      overwriteValues: (values: SubT) => setFieldValue(path, values, () => {}),
      names: proxiedPropertiesOf<SubT>(),
      calledSetValues,
      setReady: (ready: boolean) => {},
      ready: true,
      getSubForm,
      useProduce: (fn: (draft: SubT) => void) => {
        alert('not implemented');
      },
    };
  };

  return {
    validationErrors,
    validateAndSend,
    validateAndSendWithSuccess,
    clear,
    find,
    isValid,
    setValues,
    setFieldValue,
    values: formData,
    errors,
    validateForm,
    overwriteValues,
    names,
    calledSetValues,
    setReady,
    ready,
    getSubForm, // Přidání metody pro SubForm
    useProduce,
  } as Form<T>;
};

export interface Form<T extends FormValues> {
  validationErrors: ValidationError[];
  validateAndSend: (validatingData?: any) => void;
  validateAndSendWithSuccess: (onSuccess: () => void, validatingData?: any) => void;
  clear: () => void;
  find: (path: string, startsWith?: boolean) => string;
  isValid: boolean;
  setValues: (partialValues: Partial<T>) => void;
  setFieldValue: (fieldName: string, value: any, onSet?: () => void) => void;
  values: T;
  errors: FormikErrors<T>;
  validateForm: (formData: T) => void;
  overwriteValues: (values: T) => void;
  names: { [P in keyof T]?: P };
  calledSetValues: boolean;
  setReady: (ready: boolean) => void;
  ready: boolean;
  getSubForm: <SubT extends FormValues>(path: string) => Form<SubT>;
  useProduce: (fn: (draft: T) => void) => void;
}

export default useForm;
