import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgManipulation from '@components/ringil3/icons/Manipulation.tsx';
import { MultiSelect } from 'primereact/multiselect';
import { ApiContext } from '@api/api.tsx';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2.tsx';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';

interface ComponentProps {
  value: string[];
  onChange: (valueCodes: string[]) => void;
}

const ManipulationTag: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { tr } = useTranslationLgs();
  const [internalValue, setInternalValue] = useState(value);
  const { lovLoadingTypes } = useContext(ApiContext);
  const [loadingTypes, setLoadingTypes] = useState<LovWithoutIdDTO[]>();
  const acRef = React.useRef<AbortController | null>(null);

  useEffect(() => {
    acRef.current = new AbortController();

    lovLoadingTypes(d => {
      if (!acRef.current?.signal.aborted) {
        setLoadingTypes(d);
      }
    });
    return () => {
      acRef.current?.abort();
      acRef.current = null;
    };
  }, []);

  const renderValue = (value: string[]) => {
    if (value?.length > 0) {
      return value?.map(t => tr(`ManipulationTag.${t}`, `ManipulationTag.${t}`)).join(' | ');
    }
    return null;
  };

  return (
    <>
      <ButtonTag
        label={tr(`ManipulationTag.manipulation`, `Manipulace`)}
        value={renderValue(value)}
        icon={<SvgManipulation />}
        onSave={onSuccess => {
          onSuccess();
          onChange(internalValue);
        }}
      >
        {/*<MultiSelect options={loadingTypes} value={internalValue} onChange={e => setInternalValue(e.value)} optionLabel={'name'} optionValue={'code'} />*/}
        <>
          {loadingTypes?.map((item, itemIndex) => {
            return (
              <InputCheckbox
                key={itemIndex}
                value={isNotNullOrUndefined(internalValue?.find(t => t === item.code))}
                onChange={(e, checked) => {
                  if (checked) {
                    setInternalValue([...(internalValue || []), item.code]);
                  } else {
                    setInternalValue([...(internalValue || []).filter(t => t !== item.code)]);
                  }
                }}
                label={item.name}
              />
            );
          })}
        </>
      </ButtonTag>
    </>
  );
};

export default ManipulationTag;
