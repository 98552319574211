import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgYellowExclamation from '@components/ringil3/icons/YellowExclamation.tsx';
import { InputTextarea } from 'primereact/inputtextarea';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';
import { isNotBlank } from '@utils/utils.tsx';

interface ComponentProps {
  note?: string;
  onChangeNote?: (note: string) => void;
  label?: string;
  formated?: boolean;
}

const NoteTag: React.FC<ComponentProps> = ({ note, onChangeNote, label, formated = false }) => {
  const { tr } = useTranslationLgs();
  const [internalNote, setInternalNote] = useState(note);
  const [open, setOpen] = useState(false);

  const formatValue = () => {
    if (isNotBlank(note)) return formated ? <pre>{note}</pre> : note;
    return null;
  };

  return (
    <>
      <ButtonTag
        label={label ? label : tr(`NoteTag.title`, `Note`)}
        value={formatValue()}
        icon={<SvgYellowExclamation />}
        actionIcon={<SvgTagEdit />}
        onSave={() => {
          setOpen(false);
          onChangeNote(internalNote);
        }}
      >
        <InputTextarea value={internalNote ? internalNote : ''} onChange={e => setInternalNote(e.target.value)} rows={6} style={{ width: '400px' }} />
      </ButtonTag>
    </>
  );
};

export default NoteTag;
